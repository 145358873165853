import { useState } from "react";
import Modal from "../../components/Modal";

const TakeDemo = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const [email, setEmail] = useState("");

  const handlesubmit = (event: any) => {
    event.preventDefault();
    if (onClose) onClose();
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose} title=" " size="w-4/12">
      <form className="p-4" onSubmit={handlesubmit}>
        <div className="mb-4">
          <h1 className="text-lg text-center ">
            Please provide your email ID so will connect you soon{" "}
          </h1>
          <input
            className="mt-6 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            id="email"
            name="email"
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>

        <div className="flex items-center justify-end mt-8">
          <button
            type="submit"
            className="px-4 py-2 w-28 bg-blue-700 text-white rounded-lg hover:bg-blue-600 flex items-center justify-center"
          >
            Submit
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default TakeDemo;
