import LoginPage from "../pages/login/LoginPage";
import DashboardPage from "../pages/dashboard/Dashboard";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import { AuthProvider } from "./AuthContext";
import ProtectedRoute from "./ProtectedRoute";
import Home from "../pages/home/Home";

const AppRoutes = () => {
  return (
    <AuthProvider>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/dashboard" element={<DashboardPage />} />
        {/* <Route path="/uploadFile" element={<FileUpload />} /> */}
        {/* <Route path="/dashboard" element={<ProtectedRoute element={<DashboardPage />} />} /> */}
        {/* <Route path="/uploadFile" element={<ProtectedRoute element={<FileUpload />} />} /> */}
      </Routes>
    </AuthProvider>
  );
};

export default AppRoutes;
