import { Carousel } from "react-responsive-carousel";
// import './carousel-styles.css'; // Import custom styles
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Pic1 from "./../assets/Frame-1.png";
import Pic2 from "./../assets/Frame-2.png";
import storageOption from "./../assets/Frame_3.png";
import excel from "./../assets/Frame_4.png";
import cashFlow from "./../assets/Frame_5.png";

const MyCarousel = () => {
  return (
    <Carousel
      infiniteLoop
      autoPlay
      interval={4000}
      transitionTime={500}
      showThumbs={false}
      showStatus={false}
      showIndicators={true}
      showArrows={false}
      renderIndicator={(onClickHandler, isSelected, index, label) => {
        return (
          <button
            type="button"
            onClick={onClickHandler}
            aria-label={label}
            className={`inline-block w-1.5 h-1.5  rounded-full transition-colors ${
              isSelected ? "bg-white" : "bg-gray-400"
            }`}
            style={{
              position: "absolute",
              left: `${1 + index * 1}rem`,
            }}
          />
        );
      }}
    >
      {/* First banner */}
      <div className="flex">
        <div className="w-2/5 p-4 text-left">
          <h1 className="text-blue-700 text-lg font-bold mt-2">
            RUN BY EXPERIENCE, FUELED BY AI!
          </h1>
          <span className="text-4xl font-semibold ">
            Adapt To Your Needs.
            <span className="text-blue-700 font-bold">
              {" "}
              Custom Formats Supported.
            </span>{" "}
          </span>
        </div>
        <div className="w-3/5 flex items-end justify-end mt-3 ">
          <div className=" w-96 h-64">
            <img src={Pic1} alt="carousel" className="max-w-full h-auto" />
          </div>
        </div>
      </div>

      {/* 2nd banner */}
      <div className="flex">
        <div className="w-3/5 p-4 text-left">
          <h1 className="text-blue-700 text-lg font-bold mt-2 block">
            RUN BY EXPERIENCE, FUELED BY AI!
          </h1>
          <span className="text-4xl font-semibold block">
            Add New Format In Just Two Steps:
          </span>
          <h1 className="text-4xl text-blue-700 font-bold">
            {" "}
            REQUEST and SHARE.
          </h1>
        </div>
        <div className="w-2/5 flex items-end justify-end mt-3 ">
          <div className=" w-96 h-64">
            <img src={Pic2} alt="carousel" className="max-w-full h-auto" />
          </div>
        </div>
      </div>

      {/* 3rd banner */}
      <div className="flex">
        <div className="w-2/5 p-4 text-left">
          <h1 className="text-blue-700 text-lg font-bold mt-2">
            RUN BY EXPERIENCE, FUELED BY AI!
          </h1>
          <span className="text-4xl text-black font-semibold ">
            Maximize Your Storage Options.{" "}
            <span className="font-bold text-blue-700">ON-PREM or CLOUD.</span>
          </span>
        </div>
        <div className="w-3/5  flex items-end justify-end mt-3 ">
          <div className="w-96 h-64">
            <img src={storageOption} alt="carousel" className="max-w-full h-auto" />
          </div>
        </div>
      </div>

     {/* 4th banner */}
      <div className="flex">
        <div className="w-3/5 p-4 text-left">
          <h1 className="text-blue-700 text-lg font-bold mt-2">
            RUN BY EXPERIENCE, FUELED BY AI!
          </h1>
          <span className="text-4xl font-bold text-blue-700">
            Transparent Tracking.{" "}
            <span className="text-black font-semibold">
              See Your Uploads and Downloads.
            </span>
          </span>
        </div>
        <div className="w-2/5  flex items-end justify-end mt-3 ">
          <div className="w-96 h-64">
            <img src={excel} alt="carousel" className="max-w-full h-auto" />
          </div>
        </div>
      </div>

      {/* 5th banner */}
      <div className="flex">
        {/* <div className="w-7/10 p-4 text-left"> */}
        <div className="w-3/5 p-4 text-left">
          <h1 className="text-blue-700 text-lg font-bold mt-2">
            RUN BY EXPERIENCE, FUELED BY AI!
          </h1>
          <span className="text-4xl text-black font-semibold ">
            Optimize Your Cash Flow.{" "}
          </span>
          <h1 className="text-4xl font-bold text-blue-700">
            Analyze Payables and Receivables
          </h1>
        </div>
        <div className="w-2/5  flex items-end justify-end mt-3 ">
          <div className="w-96 h-64">
            <img src={cashFlow} alt="carousel" className="max-w-full h-auto" />
          </div>
        </div>
      </div>
    </Carousel>
  );
};

export default MyCarousel;
