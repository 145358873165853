import {  useEffect, useState } from "react";
import Modal from "../../components/Modal";
import Papa from "papaparse";
import axios from "axios";

interface CsvRow {
  [key: string]: string; 
}

export const EditCsv = ({
  isEditModalOpen,
  onClose,
  onCancel,
  handleSave,
  fileName,
  csvData,
  selectedOption
}: {
  isEditModalOpen?: boolean;
  onClose: () => void;
  onCancel: () => void;
  handleSave: (updatedData: string) => void; 
  fileName?: string;
  csvData?: string;
  selectedOption?: string;
}) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setData] = useState<CsvRow[]>([]);
  const [editedData, setEditedData] = useState<CsvRow[]>([]);
  const [version, setVersion] = useState("");

  const parseCSV = (csv: string) => {
    Papa.parse<CsvRow>(csv, {
      header: true,
      complete: (result) => {
        setData(result.data);
        setEditedData(result.data);
      },
      error: (error: any) => {
        console.error("Error parsing CSV:", error);
      },
    });
  };

  useEffect(() => {
    if(selectedOption === 'original_csv'){
      setVersion('original')
    }else{
      setVersion('appended')
    }
    if (csvData) {
      parseCSV(csvData);
    }
  }, [csvData]);

  const handleChange = (rowIndex: number, key: string, value: string) => {
    const updatedData = [...editedData];
    updatedData[rowIndex] = { ...updatedData[rowIndex], [key]: value };
    setEditedData(updatedData);
  };

  useEffect(() => {
    setIsModalOpen(isEditModalOpen ?? false);
  }, [isEditModalOpen]);

  const updatedFile = () => {
    const name = fileName + ".csv";
    axios.get(`${apiUrl}/csv/view/${name}`,
        {
          params: {
              version:version
          },
        }
      )
      .then((response) => {
        console.log(response);
        setEditedData(response.data);
        // setJsonData(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleSaveClick = () => {
    const updatedCsv = Papa.unparse(editedData);
    handleSave(updatedCsv); // Pass the updated CSV string to the parent
    setIsModalOpen(false);
  };


//   const handleSaveClick = () => {
//     axios.patch(`${apiUrl}/csv/edit/${fileName + ".csv"}`,editedData,
//      {
//        params: {
//          // version:'appended'
//          version:version
//        },
//      }
//    )
//    .then((response) => {
//      console.log(response);
//      updatedFile();
//    })
//    .catch(function (error) {
//      console.log(error);
//    });

//  const updatedCsv = Papa.unparse(editedData);
//  handleSave(updatedCsv);
//  setIsModalOpen(false);
// };

  const handleCancelClick = () => {
    setIsModalOpen(false);
    onCancel();
  };

  return (
    <div>
      <Modal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          if (onClose) onClose();
        }}
        title={fileName ? `${fileName} - CSV` : "Edit CSV"}
        showCancelButton={false}
        size="w-8/12"
      >
        <div className="flex flex-col">
          <div className="mt-auto flex justify-end space-x-4">
            <button
              type="button"
              className="px-4 py-2 w-32 bg-blue-500 text-white rounded-lg hover:bg-blue-600 flex items-center justify-center"
              onClick={handleSaveClick}
            >
              Save
            </button>
            <button
              type="button"
              className="px-4 py-2 w-32 border border-blue-700 text-blue-600 rounded-lg hover:bg-blue-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 flex items-center justify-center"
              onClick={handleCancelClick}
            >
              Cancel
            </button>
          </div>
        </div>
        <div className="h-96 items-center justify-between mt-12">
          <div
            className="h-96 items-center justify-between mt-12 overflow-y-scroll"
            style={{
              scrollbarWidth: "thin",
              scrollbarColor: "#3b82f6 #f1f1f1",
            }}
          >
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  {data.length > 0 &&
                    Object.keys(data[0]).map((key) => (
                      <th
                        key={key}
                        className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider"
                      >
                        {key}
                      </th>
                    ))}
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {/* {editedData.map((row, rowIndex) => ( */}
                {editedData.filter(row => Object.values(row).some(value => value !== null && value !== undefined && value !== '')).map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {Object.keys(row).map((key, colIndex) => (
                      <td
                        key={colIndex}
                        className={`px-6 py-4 whitespace-pre-wrap text-sm text-gray-500 border-b border-gray-300 ${
                          colIndex === 0 ? "w-5/6" : "w-11/12"
                        }`}
                      >
                        {/* <input
                          type="text"
                          value={row[key as keyof CsvRow]}
                          onChange={(e) =>
                            handleChange(rowIndex, key, e.target.value)
                          }
                          className="w-full px-2 py-1 border border-gray-300 rounded"
                        /> */}
                          <textarea
                          // type="text"
                          value={row[key as keyof CsvRow]}
                          onChange={(e) =>
                            handleChange(rowIndex, key, e.target.value)
                          }
                          className="h-32 px-2 py-1 border border-gray-300 rounded"
                        />
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default EditCsv;

