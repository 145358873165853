import { SetStateAction, useEffect, useState } from "react";
import Modal from "../../components/Modal";


export const EditXml = ({
  isEditModalOpen,
  onClose,
  onCancel,
  handleSave,
  fileName,
  xmlData,
}: {
  isEditModalOpen?: boolean;
  onClose: () => void;
  onCancel: () => void;
  handleSave: (updatedData: string) => void; 
  fileName?: string;
  xmlData: string ;
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
//   const [data, setData] = useState<CsvRow[]>([]);
//   const [editedData, setEditedData] = useState<CsvRow[]>([]);
  const [editedData, setEditedData] = useState("");




//   const handleChange = (rowIndex: number, key: string, value: string) => {
//     const updatedData = [...editedData];
//     updatedData[rowIndex] = { ...updatedData[rowIndex], [key]: value };
//     setEditedData(updatedData);
//   };

// useEffect(() => {
//     if(xmlData){
//         const parser = new DOMParser();
//         const xmlDoc = parser.parseFromString(xmlData, 'text/xml');
    
//         // Get the first element with the tag name 'data'
//         const dataElement = xmlDoc.getElementsByTagName('data')[0];
    
//         // Change the text content of the 'data' element
//         dataElement.textContent = 'new text';
    
//         const serializer = new XMLSerializer();
//         const newXmlData = serializer.serializeToString(xmlDoc);
    
//         setEditedData(newXmlData);
//     }
   
//   }, [xmlData]);

  useEffect(() => {
    setIsModalOpen(isEditModalOpen ?? false);
  }, [isEditModalOpen]);

  const handleSaveClick = () => {
    // const updatedCsv = Papa.unparse(editedData);
    // handleSave(updatedCsv); // Pass the updated CSV string to the parent
    handleSave(editedData);
    setIsModalOpen(false);
  };

  const handleCancelClick = () => {
    setIsModalOpen(false);
    onCancel();
  };

    // Function to handle changes in the text area
    const handleTextChange = (event: {
        target: { value: SetStateAction<string> };
      }) => {
        setEditedData(event.target.value);
      };
    
      const formattedXmlData = (xmlData:any) =>{
        // return xmlData.replace(/(<[^>]+>)/g, '\n$1').replace(/(<[^>]+>)/g, '$1\n');
        return xmlData.replace(/(<[^>]+>)/g, '\n$1').replace(/(<[^>]+>)(?!\n)/g, '$1\n');

    }
  return (
    <div>
      <Modal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          if (onClose) onClose();
        }}
        title={fileName + " - XML"}
        showCancelButton={false}
        size="w-8/12"
        >
        <div className="flex flex-col">
          <div className="mt-auto flex justify-end space-x-4">
            <button
              type="button"
              className="px-4 py-2 w-32 bg-blue-500 text-white rounded-lg hover:bg-blue-600 flex items-center justify-center"
              onClick={handleSaveClick}
            >
              Save
            </button>
            <button
              type="button"
              className="px-4 py-2 w-32 border border-blue-700 text-blue-600 rounded-lg hover:bg-blue-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 flex items-center justify-center"
              onClick={handleCancelClick}
            >
              Cancel
            </button>
          </div>
        </div>
        <div className="h-96 items-center justify-between mt-12">
        <textarea
            className="p-4 rounded-lg h-full overflow-x-auto  w-full max-w-full border border-gray-300"
            value={formattedXmlData(xmlData)}
            onChange={handleTextChange}
            style={{
              scrollbarWidth: "thin",
              scrollbarColor: "#3b82f6 #f1f1f1",
              overflowX: "auto",
              WebkitOverflowScrolling: "touch",
              whiteSpace: "nowrap",
            }}
          />
        </div>
      </Modal>
    </div>
  );
};

export default EditXml;

