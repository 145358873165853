import { SetStateAction, useEffect, useState } from "react";
import Modal from "../../components/Modal";
import axios from "axios";

interface DataType {
  [key: string]: any;
}

export const EditJson = ({
  isEditModalOpen,
  onClose,
  onCancel,
  handleSave,
  fileName,
  selectedOption
}: {
  isEditModalOpen?: boolean;
  onClose: () => void;
  onCancel: () => void;
  handleSave: (parsedData: DataType) => void;
  fileName?: string;
  selectedOption?: string;
}) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [jsonData, setJsonData] = useState("");
  const [editableData, setEditableData] = useState("");
  const [version, setVersion] = useState("");
  
  const updatedFile = () => {
    const name = fileName + ".json";
    axios.get(`${apiUrl}/json/view/${name}`,
        {
          params: {
            // version:'appended'
              version:version
          },
        }
      )
      .then((response) => {
        console.log(response);
        setJsonData(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    if(selectedOption === 'original_json'){
      setVersion('original')
    }else{
      setVersion('appended')
    }
    if (isEditModalOpen) {
      setIsModalOpen(true);
      updatedFile();
    } else {
      setIsModalOpen(false);
    }
  }, [isEditModalOpen]);

  useEffect(() => {
    setEditableData(JSON.stringify(jsonData, null, 2));
  }, [jsonData]);

  // Function to handle changes in the text area
  const handleTextChange = (event: {
    target: { value: SetStateAction<string> };
  }) => {
    setEditableData(event.target.value);
  };

  // Function to handle saving the data
  const handleSaveClick = () => {
       axios.patch(`${apiUrl}/json/edit/${fileName + ".json"}`,JSON.parse(editableData),
        {
          params: {
            // version:'appended'
            version:version
          },
        }
      )
      .then((response) => {
        console.log(response);
        updatedFile();
      })
      .catch(function (error) {
        console.log(error);
      });

    const parsedData = JSON.parse(editableData);
    handleSave(parsedData);
    setIsModalOpen(false);
  };

  const handleCancelClick = () => {
    setIsModalOpen(false);
    onCancel();
  };

  return (
    <div>
      <Modal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          if (onClose) onClose();
        }}
        title={fileName + " - JSON"}
        showCancelButton={false}
        size="w-8/12"
      >
        <div className="flex flex-col">
          <div className="mt-auto flex justify-end space-x-4">
            <button
              type="button"
              className="px-4 py-2 w-32  bg-blue-500 text-white rounded-lg hover:bg-blue-600 flex items-center justify-center"
              onClick={handleSaveClick}
            >
              Save
            </button>
            <button
              type="button"
              className="px-4 py-2 w-32 border border-blue-700 text-blue-600 rounded-lg hover:bg-blue-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 flex items-center justify-center"
              onClick={handleCancelClick}
            >
              Cancel
            </button>
          </div>
        </div>
        <div className="h-96 items-center justify-between mt-12 ">
          <textarea
            className="p-4 rounded-lg h-full overflow-x-auto  w-full max-w-full border border-gray-300"
            value={editableData}
            onChange={handleTextChange}
            style={{
              scrollbarWidth: "thin",
              scrollbarColor: "#3b82f6 #f1f1f1",
              overflowX: "auto",
              WebkitOverflowScrolling: "touch",
              whiteSpace: "nowrap",
            }}
          />
        </div>
      </Modal>
    </div>
  );
};

export default EditJson;
