import Modal from "../../components/Modal";

const OtherProduct = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} title=" " size="w-3/12">
      <div className="p-8 text-center  font-bold text-lg">
        <span>Coming soon !</span>
      </div>
    </Modal>
  );
};

export default OtherProduct;
