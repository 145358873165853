import Carousel from "../../components/Carousel";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import profilePic from "./../../assets/profilePic.jpg";
import SupportedInvoice from "../home/SupportedInvoice";
import productIcon from "./../../assets/productIcon.svg"

 const DashboardPage = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();

  console.log(window.location.href);
  const handleLogout = () => {
    navigate("/");
    setIsDropdownOpen(false);
  };

  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);
  return (
    <div className="p-4">
      <div className="flex flex-wrap">
        <div className="flex-shrink-0 mb-2 w-5/12 pr-0 h-full">
          <div className="bg-white shadow-lg rounded-lg p-6 mb-4 card">
            <div className="flex items-start space-x-2">
            <img
                src={productIcon}
                alt="Invoicify"
                className="w-36 max-w-full"
              />
            </div>
          </div>
        </div>
        <div className="flex-shrink-0 mb-2 w-2/12 h-full ">
          <div className="bg-blue-700 shadow-lg text-white rounded-lg p-6  mb-4">
            <div className="flex space-x-2">
              <span className="text-sm mt-1">INVOICES PROCESSED</span>
              <span className="text-xl font-bold ml-0">:</span>
              <h1 className="text-xl font-bold font-Inter text-center">110</h1>
            </div>
          </div>
        </div>
        <div className="flex-shrink-0 mb-2 w-5/12 h-full ">
          <div className="bg-white shadow-lg rounded-lg p-4 mb-4 card">
            <div className="flex justify-end relative">
              <img
                src={profilePic}
                alt="Profile"
                className="w-12 h-12 rounded-full cursor-pointer"
                onClick={toggleDropdown}
              />
              {isDropdownOpen && (
                <div
                  ref={dropdownRef}
                  className="absolute right-0 mt-14 w-48 bg-white text-black rounded shadow-lg z-10"
                >
                  <button
                    className="w-full text-left px-6 py-2 hover:bg-gray-200"
                    onClick={handleLogout}
                  >
                    Logout
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="bg-blue-100 w-full  h-80 shadow-lg rounded-lg p-6 mb-2 card">
        <Carousel />
      </div>
      <SupportedInvoice isIconClick={true} />
    </div>
  );
}

export default DashboardPage;