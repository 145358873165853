import {
  IconChevronDown,
  IconChevronLeft,
  IconCloudUpload,
} from "@tabler/icons-react";
import React, { useState, ChangeEvent, DragEvent } from "react";
import ConfirmationPopup from "./ConfirmationPopup";
import Modal from "../../components/Modal";
import axios from "axios";
import WarningPopup from "../../components/WarningPopup";
import pdf from "./../../assets/pdf-icon.png";

const FileUpload = ({
  open,
  closeUploadFileModal,
}: {
  open: boolean;
  closeUploadFileModal: () => void;
}) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [dragging, setDragging] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState<number>(0);
  const [abortController, setAbortController] =
    useState<AbortController | null>(null);
  const [fileName, setFileName] = useState<string | null>(null);
  const [isConvertToJson, setIsConvertToJson] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFileDetailsModalOpen, setFileDetailsModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [formData, setFormData] = useState<FormData | null>(null);
  const [fileFormatErrorMessage, setFileFormatErrorMessage] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [isConvertTocsv, setIsConvertTocsv] = useState(false);
  const [isConvertToXml, setIsConvertToXml] = useState(false);

  const handleDragOver = (e: DragEvent<HTMLDivElement>): void => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = (): void => {
    setDragging(false);
  };

  const handleDrop = (e: DragEvent<HTMLDivElement>): void => {
    e.preventDefault();
    setDragging(false);
    if (e.dataTransfer.files.length > 0) {
      handleFileUpload(e.dataTransfer.files[0]);
    }
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>): void => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      if (file.type === "application/pdf") {
        handleFileUpload(file);
      } else {
        setErrorMessage("Invalid file type. Please upload a PDF file.");
      }
    }
  };

  const handleFileUpload = (file: File): void => {
    if (file.type !== "application/pdf") {
      setErrorMessage("Invalid file type. Please upload a PDF file.");
      return;
    }
    setErrorMessage(null);
    setFileName(file.name);
    setUploading(true);
    setIsModalOpen(true);
    setProgress(0);

    //upload process
    const uploadInterval = setInterval(() => {
      setProgress((prev) => {
        if (prev >= 100) {
          clearInterval(uploadInterval);
          setUploading(false);
          return 100;
        }
        return prev + 10;
      });
    }, 500);

    const controller = new AbortController();
    setAbortController(controller);
    openModal();
    openFileDetailsModal();
    const formData = new FormData();
    formData.append("file", file);
    setFormData(formData);
  };

  const handleCancel = (): void => {
    if (abortController) {
      abortController.abort(); // Abort the ongoing upload
      setUploading(false);
      setProgress(0);
      setFileName(null);
    }
  };

  const handleConvertToJson = async () => {
    const response = await axios
      .post(`${apiUrl}/process-invoice`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        params: {
          company_name: selectedOption,
        },
      })
      .then((response) => {
        setIsConvertToJson(true);
      })
      .catch(function (error) {
        setFileFormatErrorMessage(error.response?.data.detail);
        setIsConvertToJson(false);
      });
    console.log("response", response);
  };

  const handleClosePopup = () => {
    setFileName(null);
    setIsConvertToJson(false);
    setIsConvertTocsv(false);
    // setIsConvertToCSV(false);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setFileName(null);
  };

  const openFileDetailsModal = () => {
    setFileDetailsModalOpen(true);
  };

  const closeFileDetailsModal = () => {
    setFileDetailsModalOpen(false);
    setFileName(null);
    setFileFormatErrorMessage("");
  };

  const handleChange = (event: any) => {
    setSelectedOption(event.target.value);
  };

  const options = [
    { label: "CMA_CGM", value: "cma_cgm" },
    { label: "Hapag", value: "hapag" },
    { label: "Maersk", value: "maersk" },
    { label: "MSC", value: "msc" },
  ];

  const handleConvertToCsv = async () => {
    const response = await axios.post(`${apiUrl}/convert/pdf_to_csv`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        params: {
         company_name: selectedOption
        }
      })
      .then((response) => {
        console.log(response);
        setIsConvertTocsv(true);
      })
      .catch(function (error) {
        console.log(error.response?.data.detail);
        setFileFormatErrorMessage(error.response?.data.detail)
        setIsConvertTocsv(false);
      });
    console.log("response", response);
  }

  const handleConvertToXml = async () => {
    await axios.post(`${apiUrl}/convert/pdf_to_xml`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        params: {
         company_name: selectedOption
        }
      })
      .then((response) => {
        console.log(response);
        setIsConvertToXml(true);
      })
      .catch(function (error) {
        console.log(error.response?.data.detail);
        setFileFormatErrorMessage(error.response?.data.detail)
        setIsConvertToXml(false);
      });
  }

  return (
    <div>
      <div className="flex  items-center justify-center h-full w-full">
        {!uploading && !fileName && (
          <Modal
            isOpen={open}
            onClose={closeUploadFileModal}
            title="Upload PDF"
            showCancelButton={false}
            size="w-7/12"
          >
            <div>
              <div className=" flex flex-col w-52 max-w-xs">
                <label
                  className="font-bold mb-2 text-gray-700 "
                  htmlFor="dropdown"
                >
                  Payable To:
                </label>
                <div className="border border-gray-300 rounded-lg relative">
                  <select
                    className="appearance-none bg-transparent w-full py-2 pr-8 pl-3 "
                    id="dropdown"
                    value={selectedOption}
                    onChange={handleChange}
                  >
                    <option value="">Select</option>
                    {options.map((option, index) => (
                      <option
                        key={option.value}
                        value={option.value}
                        disabled={index !== 0}
                      >
                        {option.label}
                      </option>
                    ))}
                  </select>

                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <IconChevronDown stroke={1} />
                  </div>
                </div>
              </div>
              <div
                className={`w-full max-w-4xl mt-8  bg-sky-50 p-4 border  rounded-lg ${
                  dragging ? "border-blue-500" : "border-gray-300 border-2"
                } ${selectedOption ? "" : "opacity-55 pointer-events-none"}`}
                style={{ height: "350px" }}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
              >
                <input
                  type="file"
                  id="file-upload"
                  className="hidden"
                  onChange={handleFileChange}
                />
                <label
                  htmlFor="file-upload"
                  className="flex flex-col items-center justify-center h-full cursor-pointer"
                >
                  <IconCloudUpload
                    stroke={2}
                    color="#3b82f6"
                    className="h-14 w-14"
                  />
                  <span className=" mt-6 font-medium ">
                    Choose a file or drag & drop it here.
                  </span>
                  <span className="text-gray-500 ">
                    PDF formats, up to 1 MB
                  </span>
                  <button
                    type="button"
                    onClick={() =>
                      document.getElementById("file-upload")?.click()
                    }
                    className="mt-6 w-44 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
                  >
                    Browse File
                  </button>
                  {errorMessage && (
                    <p className="mt-4 text-red-500">{errorMessage}</p>
                  )}
                </label>
              </div>
            </div>
          </Modal>
        )}

        {uploading && (
          <Modal
            isOpen={isModalOpen}
            onClose={closeModal}
            title="Upload PDF"
            showCancelButton={false}
            size="w-5/12"
          >
            <div
              className={`w-full max-w-2xl  bg-sky-50 p-4 rounded-lg border-2 border-gray-300}`}
            >
              <div className="mt-10 flex flex-col items-center mb-2">
                <span className="mb-1 text-xs font-semibold text-gray-600">
                  {progress}% Completed
                </span>
                <div className="relative flex-grow w-full bg-gray-300 rounded-full h-2.5 mt-1">
                  <div
                    className="absolute top-0 left-0 bg-blue-500 h-2.5 rounded-full"
                    style={{ width: `${progress}%` }}
                  ></div>
                </div>
                <span className="text-xs font-semibold text-gray-600 mt-2">
                  Uploading File...
                </span>
              </div>
              <div className="mb-10 flex justify-center">
                <button
                  type="button"
                  onClick={handleCancel}
                  className="mt-8 px-4 py-2 w-44 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
                >
                  Cancel
                </button>
              </div>
            </div>
          </Modal>
        )}

        {fileName && !uploading && !isConvertToJson && !isConvertTocsv && !isConvertToXml && (
            <Modal
            isOpen={isFileDetailsModalOpen}
            onClose={closeFileDetailsModal}
            title="Upload PDF"
            showCancelButton={false}
            size="w-5/12"
          >
            <div>
              <div className="relative flex items-center justify-between p-2 w-full h-24 border-2 border-gray-200 rounded-lg bg-sky-50">
                <div className="flex items-center">
                  <img src={pdf} alt="pdf-icon" className="h-10 w-10 " />
                  <div className="ml-4 flex flex-col">
                    <p className="text-gray-700">{fileName}</p>
                    {/* <h2 className="text-gray-500 text-sm">15MB</h2> */}
                  </div>
                </div>
                {/* <IconX
              stroke={1}
              className="text-gray-600 h-4 w-4 absolute top-2 right-2 cursor-pointer"
              onClick={handleCancel}
            /> */}
              </div>
      
              <h2 className="font-medium text-center mt-8">Convert To</h2>
              <div className="flex flex-col items-center mt-4">
                <div className="flex space-x-4">
                  <button
                    type="button"
                    onClick={handleConvertToJson}
                    className="px-4 py-2 w-36 bg-blue-500 text-white rounded-lg hover:bg-blue-600 flex items-center justify-center"
                  >
                    JSON
                  </button>
                  <button
                    type="button"
                    onClick={handleConvertToCsv}
                    className="px-4 py-2 w-36 bg-blue-500 text-white rounded-lg hover:bg-blue-600 flex items-center justify-center"
                  >
                    CSV
                  </button>
                  <button
                    type="button"
                    onClick={handleConvertToXml}
                    className="px-4 py-2 w-36 bg-blue-500 text-white rounded-lg hover:bg-blue-600 flex items-center justify-center"
                  >
                    XML
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        )}
      </div>

      <ConfirmationPopup
        isConvertToJson={isConvertToJson}
        isConvertTocsv={isConvertTocsv}
        isConvertToXml={isConvertToXml}
        onClose={handleClosePopup}
        uploadedFileName={fileName ? fileName : ""}
      />

      {fileFormatErrorMessage && (
        <WarningPopup
          isOpen={open}
          onClose={closeFileDetailsModal}
          title=" "
          showCancelButton={true}
          size="w-2/5"
        >
          <div className="p-3 text-center">
            <span className=" text-lg">{fileFormatErrorMessage}</span>
          </div>
        </WarningPopup>
      )}
    </div>
  );
};

export default FileUpload;