import { useEffect, useState } from "react";
import Modal from "../../components/Modal";
import { Download } from "../../components/Download";
import axios from "axios";
import EditXml from "./EditXml";
import { IconChevronDown } from "@tabler/icons-react";
import CalculatorPage from "../../components/Calculator";

export const ViewXml = ({
  isXmlView,
  onClose,
  uploadedFileName,
}: {
  isXmlView?: boolean;
  onClose: () => void;
  uploadedFileName?: string;
}) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fileName, setFileName] = useState("");
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [xmlData, setXmlData] = useState("");
  const [selectedOption, setSelectedOption] = useState<string>("");

  useEffect(() => {
    setSelectedOption('')
    if (isXmlView) {
      setIsModalOpen(true);
      previewFile("appended");
    } else {
      setIsModalOpen(false);
    }
    if (uploadedFileName) {
      const fileName = uploadedFileName.split(".")[0];
      setFileName(fileName);
    }
  }, [apiUrl, fileName, isXmlView, uploadedFileName]);

  const previewFile = (version: string) => {
    axios.get(`${apiUrl}/view/xml/${fileName + ".xml"}`, {
        params: {
            version: version,
        },
      })
      .then((response) => {
        console.log("response",response)
        setXmlData(response.data);
        console.log("xmlData",xmlData)
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleEdit = () => {
    // previewFile();
    setIsEditModalOpen(true);
  };

  const handleClosePopup = () => {
    setIsEditModalOpen(false);
    setIsModalOpen(false);
    if (onClose) onClose();
  };

  const handleCancel = () => {
    setIsEditModalOpen(false);
  };

  const handleSave = (updatedData: any) => {
    setXmlData(updatedData);
    // Close the edit modal
    setIsEditModalOpen(false);
  };

    const handleDownload = async () => {
      const fileName = uploadedFileName?.split(".")[0] + ".xml";
    //   await axios
    //     .get(`${apiUrl}/download/xml/${fileName}`)
    //     .then((response) => {
    //       // Download(response.data, fileName);
    //     //   Download(xmlData, fileName);
    //     })
    //     .catch(function (error) {
    //       console.log(error);
    //     });
    const response = await axios.get(`${apiUrl}/download/xml/${fileName}`, {
        responseType: 'blob', 
      });
      if (response.headers['content-type'] === 'application/xml') {
        const file = new Blob([response.data], { type: 'application/xml' });
        const fileURL = URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = fileURL;
        link.download = response.headers['content-disposition']
          ? response.headers['content-disposition'].split('filename=')[1].replace(/['"]/g, '') 
          : fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(fileURL);
      } else {
        console.error('Unexpected content type:', response.headers['content-type']);
      }
    };

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    setSelectedOption(value);
    switch (value) {
      case "original_xml":
        // previewFile()
         previewFile("original");
        break;
      case "appended_xml":
        // previewFile()
        previewFile("appended");
        break;
      default:
        setSelectedOption("");
        break;
    }
  };

//   const escapeXML = (xmlString:any) =>{
//     return xmlString
//     .replace(/&/g, '&amp;')
//     .replace(/</g, '&lt;')
//     .replace(/>/g, '&gt;')
//   }

const formattedXmlData = (xmlData:any) =>{
    return xmlData.replace(/(<[^>]+>)/g, '\n$1').replace(/(<[^>]+>)(?!\n)/g, '$1\n');
}
  return (
    <div>
      <Modal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          if (onClose) onClose();
        }}
        title={fileName + " - XML"}
        showCancelButton={false}
        size="w-8/12"
      >
        <div className="flex flex-col ">
          <div className="mt-auto flex justify-end space-x-4">
            <div className="border border-gray-300 rounded-lg relative">
              <select
                className="appearance-none px-6 py-2 border border-blue-700 text-blue-600 rounded-lg  focus:outline-none focus:ring-2 focus:ring-blue-500 flex items-center justify-center"
                id="dropdown"
                value={selectedOption}
                onChange={handleChange}
              >
                <option value="">View Option</option>
                {selectedOption === "original_xml" ? (
                  <option value="appended_xml">Appended XML</option>
                ) : (
                  <option value="original_xml">Original XML</option>
                )}
                
              </select>
              <div className="pointer-events-none absolute inset-y-0 -right-1 flex items-center px-2 text-gray-700">
                <IconChevronDown stroke={1} color="#3b82f6" />
              </div>
            </div>
            <button
              type="button"
              className="px-4 py-2 w-32 border border-blue-700 text-blue-600 rounded-lg hover:bg-blue-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 flex items-center justify-center"
              onClick={handleEdit}
            >
              Edit
            </button>
            <button
              type="button"
              className="px-4 py-2 w-32  bg-blue-500 text-white rounded-lg hover:bg-blue-600 flex items-center justify-center"
              onClick={handleDownload}
            >
              Download
            </button>
          <CalculatorPage />
          </div>

        </div>
        <h1 className="font-bold text-sm mb-4">
              {selectedOption === "original_xml"
                ? "Original XML"
                : "Appended XML"}
            </h1>
        <div
          className="h-96  items-center justify-between mt-12 overflow-y-scroll "
          style={{
            scrollbarWidth: "thin",
            scrollbarColor: "#3b82f6 #f1f1f1",
          }}
        >
          <div>
           
            <pre className="p-4 rounded-lg overflow-x-auto whitespace-pre-wrap w-max">
           {/* <code> */}
            {xmlData ? formattedXmlData(xmlData)  : "Loading..."}
           {/* </code> */}
            </pre>
          </div>
        </div>
      </Modal>

      <EditXml
        isEditModalOpen={isEditModalOpen}
        onClose={handleClosePopup}
        onCancel={handleCancel}
        handleSave={handleSave}
        fileName={fileName}
        xmlData={xmlData}
      />
    </div>
  );
};

export default ViewXml;
