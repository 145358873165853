import { useState } from "react";
import Modal from "../../components/Modal";

const NeedNewFormat = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handlesubmit = (event: any) => {
    event.preventDefault();
    console.log("email", email);
    console.log("Message", message);
    if (onClose) onClose();
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose} title=" " size="w-4/12">
      <form className="p-4" onSubmit={handlesubmit}>
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="email"
          >
            Email
          </label>
          <input
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            id="email"
            name="email"
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="message"
          >
            Message
          </label>
          <textarea
            className="shodow appearance-none border rounded w-full h-32 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            id="message"
            placeholder="Message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </div>
        <div className="flex items-center justify-end">
          <button
            type="submit"
            className="px-4 py-2 w-28 bg-blue-700 text-white rounded-lg hover:bg-blue-600 flex items-center justify-center"
          >
            Submit
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default NeedNewFormat;
