import React, { useState } from "react";
import Calculator from "awesome-react-calculator";
import { IconCalculator } from "@tabler/icons-react";

const CalculatorPage = () => {
  const [isCalculatorVisible, setIsCalculatorVisible] = useState(false);

  // Toggle calculator visibility
  const handleButtonClick = () => {
    if (isCalculatorVisible) {
      setIsCalculatorVisible(false);
    } else {
      setIsCalculatorVisible(true);
    }
  };

  return (
    <div>
      <div className="relative group inline-block" onClick={handleButtonClick}>
      <IconCalculator stroke={1} color="#3b82f6" className="h-9 w-9" />
      <div className="absolute  w-32 right-0 mt-2 bg-blue-50 text-center text-sm font-normal rounded-lg p-1 opacity-0 group-hover:opacity-100 transition duration-200">
              Calculator
            </div>
          </div>
      {isCalculatorVisible && (
        <div className="absolute right-5 m-1  w-64 h-80 z-10  bg-white shadow-lg">
          <Calculator />
        </div>
      )}
    </div>
  );
};

export default CalculatorPage;
