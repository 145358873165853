import React, { useState, useEffect } from 'react';
import Papa from 'papaparse';
interface CsvRow {
  issuer: string;
  date: string;
  invoice_number: string;
  bill_of_lading: string;
  customer: string;
  cust_ref: string;
  invoiced_by: string;
  voyage: string;
  local_voyage_ref: string;
  vessel: string;
  call_date: string;
  place_of_receipt: string;
  discharge_port: string;
  load_port: string;
  place_of_delivery: string;
  commodity_table: string;
  container_numbers: string;
  quote_reference: string;
  service_contract: string;
  charges_table: string; 
  rate_of_exchange_table: string;
  total_excluding_tax: string;
  carrier_no: string;
  amount: string;
  payable_by: string;
  ifsc_code: string;
  gst_table: string;
  total_gst_tax: string;
  total_including_tax: string;
  currency: string;
  desc: string;
  payable_to_address: string;
  payable_to_tel: string;
  payable_to_fax: string;
  posp_address: string;
  posp_gstin: string;
  invoice_to_address: string;
  invoice_to_gstin: string;
  name: string;
  tel: string;
  final_gst_table: string;
}

const CsvDataDisplay = ({ csvData }: { csvData: string }) => {
  const [data, setData] = useState<CsvRow[]>([]);

  const parseCSV = (csv: string) => {
    Papa.parse<CsvRow>(csv, {
      header: true,
      complete: (result) => {
        setData(result.data);
      },
      error: (error: any) => {
        console.error('Error parsing CSV:', error);
      }
    });
  };

  useEffect(() => {
    parseCSV(csvData);
  }, [csvData]);

  return (
    <div >
      {data.length > 0 ? (
        <div className="relative">
          <div className=" max-h-96"> 
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50 sticky top-0 ">
                <tr>
                {Object.keys(data[0]).map((key, index) => (
                    <th
                      key={key}
                      className={`px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider ${
                        index === 0 ? 'w-1/4' : 'w-1/4' 
                      }`}
                    >
                      {key}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
              {data.filter(row => Object.values(row).some(value => value !== null && value !== undefined && value !== '')).map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {Object.values(row).map((value, colIndex) => (
                      <td
                        key={colIndex}
                        className="px-6 py-4 whitespace-nowrap text-sm text-black border-b border-gray-300 break-words"
                      >
                        {typeof value === 'string' ? value : JSON.stringify(value)}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        "Loading..."
      )}
    </div>
  );
};

export default CsvDataDisplay;
